import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useForm, Controller } from "react-hook-form"
import styled from "styled-components"
import axios from "axios"
import DatePicker from "react-datepicker"

import {
  SEO,
  Layout,
  PageHeader,
  MainWrapper,
  OnClickButton,
  Spinner,
} from "components"
import background from "../images/back-texture.png"

const HowBack = styled.section`
  background: var(--whiteChalk);
  position: relative;
  background-image: ${({ background }) =>
    `linear-gradient(
            rgba(255, 255, 255, 0.95),
            hsl(0deg 0% 100%)
          ),url(${background})` || "var(--black)"};
`

const CateringText = styled.div`
  padding: 70px 30px;

  div {
    display: flex;
    justify-content: center;

    > p {
      text-align: center;
      max-width: 450px;
      margin-bottom: 30px;
      line-height: 20px;
    }
  }
`

const CateringWrapper = styled.div`
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
`
export const ContactFormContent = styled.form`
  min-width: 450px;
  max-width: 450px;

  @media (max-width: 992px) {
    min-width: 100%;
    max-width: 100%;
  }
  font-size: var(--fontSizeThree);
  display: block;
  color: var(--black);
  input,
  textarea {
    font-family: "Lato", Sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    border: 1px solid #e2e2e2;
    box-shadow: none;
    outline: none;
    transition: 0.15s;
    padding: 10px 13px 9px 13px;
    margin: 1rem 0;
    border-radius: 6px;
    width: 100%;
    background-color: #e5e1e1 !important;
  }

  .radio {
    padding-bottom: 10px;
    span {
      font-size: 13px;
      padding-left: 3px;
    }
    li {
      height: 25px;
    }
    input {
      width: 10%;
      margin: 10px 0;
      cursor: pointer;
    }

    label {
      font-size: 13px;
      margin-top: -3px;
      cursor: pointer;
    }
  }

  .up-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;

    @media (max-width: 992px) {
      grid-template-columns: 1fr;
      grid-column-gap: 0px;
      grid-row-gap: 00px;
    }
  }
  .submit {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }
`

const CateringPage = () => {
  // form info
  const [delivery, setDelivery] = useState(false)
  // button click
  const [loading, setLoading] = useState(false)
  // something went wrong
  const [message, setMessage] = useState("")

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm()

  // Form Submit
  const onSubmit = async (data, e) => {
    setLoading(true)

    console.log(data)

    await axios
      .post("https://dm2msyt9n5.execute-api.us-east-1.amazonaws.com/final/", {
        ...data,
        delivery: delivery
          ? "This customer wants Delivery"
          : "This customer want to Pickup",
        destination: "admin@spruzzopalisades.com",
      })
      .then(res => {
        setMessage("Thank you! We'll connect you soon!")
        e.target.reset()
      })
      .catch(err => setMessage("Something went wrong!"))

    setLoading(false)
  }

  return (
    <Layout>
      <SEO title="Catering" />
      <PageHeader title="Catering">
        <StaticImage
          src="../images/slider/3.jpg"
          placeholder="blurred"
          alt="Menu"
          quality="100"
        />
      </PageHeader>
      <HowBack background={background}>
        <MainWrapper>
          <CateringText>
            <div>
              <p>
                From an office party to an at-home celebration, we can help with
                your next event. Please fill out the form below and we’ll be in
                touch soon!
              </p>
            </div>
            <div>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://hhsclientfiles.s3.amazonaws.com/spruzzo/menu/catering722.pdf"
                download
              >
                <OnClickButton>Download Catering Menu</OnClickButton>
              </a>
            </div>
          </CateringText>
          <CateringWrapper>
            <ContactFormContent onSubmit={handleSubmit(onSubmit)}>
              <div className="up-form">
                <input
                  placeholder="Full Name"
                  {...register("name", { required: true, maxLength: 30 })}
                />
                {errors.fullName?.type === "required" && "Name is required!"}
                <input
                  placeholder="Email"
                  {...register("email", { required: true })}
                />
                {errors.email?.type === "required" && "Email is required!"}
              </div>

              <input
                placeholder="Phone"
                {...register("phone", { required: true })}
              />
              {errors.email?.type === "required" && "Phone is required!"}

              <Controller
                control={control}
                name="OrderDate"
                render={({ field }) => (
                  <DatePicker
                    placeholderText="Select date"
                    onChange={date => field.onChange(date)}
                    selected={field.value}
                  />
                )}
              />
              {errors.dateInput && <span>This field is required</span>}

              <ul className="radio">
                <span>Select Food Collect Option</span>
                <li>
                  <input
                    onClick={() => setDelivery(false)}
                    type="radio"
                    id="html"
                    name="fav_language"
                    value="HTML"
                  />
                  <label htmlFor="html">Pickup</label>
                </li>

                <li>
                  <input
                    type="radio"
                    id="css"
                    name="fav_language"
                    value="CSS"
                    onClick={() => setDelivery(true)}
                  />
                  <label htmlFor="css">Delivery</label>
                </li>
              </ul>

              {delivery && (
                <>
                  <input placeholder="Address" {...register("address")} />

                  <input placeholder="City" {...register("city")} />

                  <div className="up-form">
                    <input placeholder="State" {...register("state")} />

                    <input placeholder="Zip Code" {...register("zip")} />
                  </div>
                </>
              )}

              <textarea
                rows="6"
                placeholder="Additional Information"
                {...register("message")}
              />

              {message ? <span>{message}</span> : ""}

              <div className="submit">
                <OnClickButton disabled={loading} type="submit">
                  {loading ? <Spinner /> : "SEND YOUR MESSAGE"}
                </OnClickButton>
              </div>
            </ContactFormContent>
          </CateringWrapper>
        </MainWrapper>
      </HowBack>
    </Layout>
  )
}

export default CateringPage
